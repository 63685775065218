<template>
  <div class="area" v-show="$route.name!=='EnergyConsumption'&&$route.name!=='Msite'">
    <div class="area_title">
      教学楼
      <div class="area_title_icon"></div>
      <div class="area_title_line">
        <div class="area_title_line_ract"></div>
      </div>
    </div>
    <div class="area_content_">
      <div class="building_chose building_chose_1">
        <el-select
            v-model="buildingName"
            style="width: 230px"
            placeholder="选择教学楼"
            placeholder-class="placeholderStyle"
            @change="choseBuilding(buildingName)"
            :teleported="false"
            @visible-change="showFloors=!$event"
        >
          <el-option
              v-for="item in buildingArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
        <div class="recovery_btn" :class="btnClick===true?'recovery_btn_1':''" @click="recovery">
          <p class="text">恢复</p>
        </div>
      </div>
      <div class="floors" v-show="showFloors">
        <div class="floor" v-for="item in floorList" :key="item.value">
          <div class="floor_" :class="item.value===choseIndex ? 'chose_floor':''" @click="choseFloor($event)">
            {{ item.label }}楼
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="floor_list" v-show="$route.name==='EnergyConsumption'&&showFloors">
    <div class="floor1" v-for="item in floorList" :key="item.value">
      <div class="floor_" :class="item.value===choseIndex ? 'chose_floor':''" @click="choseFloor($event)">
        {{ item.label }}楼
      </div>
    </div>
  </div>
  <div class="view_switching">
    <div class="view_btn" @click="restorePerspective">
      <div class="view_back"></div>
      <div class="restore_perspective"></div>
    </div>
    <div class="view_btn" @click="overlooking">
      <div class="view_back"></div>
      <div class="overlooking"></div>
    </div>
  </div>
</template>

<script>
import Global from "../js/global";
import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import flvjs from "flv.js";
import Message from "../js/Message";
import DeviceAPI from "../js/deviceAPI";

let hiScene = null;
let gltfArr = [];
let floorArr = [];
let hiddenFloor = [];
let displayFloor = [];
let appearFloor = [];
let inRoomEquipment = null
let buildingIndex = '';
let floorIndex = '';
let deviceID = '';
let deviceType = '';

export default {
  name: "ChoseBuilding",
  data() {
    return {
      choseIndex: "",
      btnClick: false,
      floorListDefault: [
        {value: 1, label: "1"},
        {value: 2, label: "2"},
        {value: 3, label: "3"},
        {value: 4, label: "4"},
        {value: 5, label: "5"},
        {value: 6, label: "6"},
        {value: 7, label: "7"},
        {value: 8, label: "8"},
        {value: 9, label: "9"}
      ],
      floorList: [],

      buildingArr: [],
      buildingName: "",


      isVideo: false,
      iconPosition: [
        new BABYLON.Vector3(-185.00557358577527, 107.22377586364745, 14.809145112899813 - 10.0),
        new BABYLON.Vector3(-189.4168176489234, 107.20028686523436, 165.7496039319094 - 10.0),
        new BABYLON.Vector3(-95.27499130552343, 107.44832420349118, -70.38394281330622 - 10.0),
        new BABYLON.Vector3(9.261631819972195, 119.1135932001423, -172.28815032867044 - 10.0),
        new BABYLON.Vector3(12.934067909903945, 114.10020828247076, 57.86779780712284 - 10.0),
        new BABYLON.Vector3(-319.26153298839307, 104.50598622338659, 5.687250331856831 - 10.0),
        new BABYLON.Vector3(-312.3748889351093, 105.74987857811965, 180.7908278892785 - 10.0),
        new BABYLON.Vector3(56.84806937777944, 107.80513954162595, 413.50868940336693 - 10.0),
        new BABYLON.Vector3(-47.08009765626343, 105.05788573998541, 250.73105878531268 - 10.0),
        new BABYLON.Vector3(-99.34751894681443, 110.78010264641085, 310.2631780540676 - 10.0),
        new BABYLON.Vector3(-91.95891577999328, 107.74034542596502, 389.2801625481765 - 10.0)
      ],
      floorNum: "",
      isLight: true,
      isLightOn: true,
      classroomNumber: "",
      equipmentName: '',
      sliderValue: 50,
      routeName: "",
      chosePoint: null,
      loading: false,
      showFloors: true,
      isShellGone: false,
      floorChange: false,

    }
  },
  props: ["broadcastInfo", "monitorInfo", "manageInfo", "lightInfo", "useWaterInfo", "useElectricInfo", "coInfo", "waterInfo", "fanInfo",
    "liftInfo", "airInfo", "dynamoInfo"],
  emits: ["getBroadcastInfo", "getMonitorInfo", "getManageInfo", "getLightInfo", "getCoInfo", "getUseWaterInfo", "getUseElectricInfo", "getWaterInfo",
    "getFanInfo", "getLiftInfo", "getAirInfo", "getDynamoInfo"],
  beforeUnmount() {
    this.recovery();
    scene.collisionsEnabled = true;
  },
  mounted() {
    this.routeName = this.$route.name;
    Global.Func.choseBuilding = this.choseBuilding
    let time = setInterval(() => {
      if (Global.progress === 100) {
        hiScene = Global.hiScene;
        this.initOperation()
        // this.setIconPlane()
        this.clickIcon()
        clearInterval(time)
      }
    }, 100)
  },
  unmounted() {
  },
  watch: {
    manageInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getManageInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["inside"][buildingIndex][floorIndex].find(device => device.name === deviceID)
        if (newObj.isManage) {
          deviceOfModel.meshOn.setEnabled(false)
          deviceOfModel.meshOff.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.meshOn.setEnabled(newObj.isRoomUse)
          deviceOfModel.meshOff.setEnabled(!newObj.isRoomUse)
        }

        // 2、改变接口数据
        let deviceOfAPI = DeviceAPI.deviceInterfaceData.broadcast.find(item => item.DEVICE_ID === deviceID)
        if (deviceOfAPI.DEVICE_STATUS !== newObj.isRoomUse) {
          deviceOfAPI.DEVICE_STATUS = newObj.isRoomUse
        }

      },
      deep: true
    },
    broadcastInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getBroadcastInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["inside"][buildingIndex][floorIndex].find(device => device.name === deviceID)
        console.log('deviceOfModel', deviceOfModel)
        if (newObj.isBroadcast) {
          deviceOfModel.meshOn.setEnabled(false)
          deviceOfModel.meshOff.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.meshOn.setEnabled(newObj.isBroadcastOn)
          deviceOfModel.meshOff.setEnabled(!newObj.isBroadcastOn)
        }

        // 2、改变接口数据
        let deviceOfAPI = DeviceAPI.deviceInterfaceData.broadcast.find(item => item.DEVICE_ID === deviceID)
        if (deviceOfAPI.DEVICE_STATUS !== newObj.isBroadcastOn) {
          deviceOfAPI.DEVICE_STATUS = newObj.isBroadcastOn
        }
        if (deviceOfAPI.DEVICE_VALUE !== newObj.sliderValue) {
          deviceOfAPI.DEVICE_VALUE = newObj.sliderValue
        }
      },
      deep: true
    },
    monitorInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getMonitorInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["inside"][buildingIndex][floorIndex].find(device => device.name === deviceID)
        if (newObj.isMonitor) {
          deviceOfModel.mesh.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.mesh.setEnabled(true)
        }
        // 2、改变接口数据

      },
      deep: true
    },
    lightInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getLightInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["inside"][buildingIndex][floorIndex].find(device => device.name === deviceID)
        console.log('deviceOfModel', deviceOfModel)
        if (newObj.isLight) {
          deviceOfModel.meshOn.setEnabled(false)
          deviceOfModel.meshOff.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.meshOn.setEnabled(newObj.isLightOn)
          deviceOfModel.meshOff.setEnabled(!newObj.isLightOn)
        }

        // 2、改变接口数据
        let deviceOfAPI = DeviceAPI.deviceInterfaceData.broadcast.find(item => item.DEVICE_ID === deviceID)
        if (deviceOfAPI.DEVICE_STATUS !== newObj.isLightOn) {
          deviceOfAPI.DEVICE_STATUS = newObj.isLightOn
        }
        if (deviceOfAPI.DEVICE_VALUE !== newObj.sliderValue) {
          deviceOfAPI.DEVICE_VALUE = newObj.sliderValue
        }
      },
      deep: true
    },
    useWaterInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getUseWaterInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["inside"][buildingIndex][floorIndex].find(device => device.name === deviceID)
        if (newObj.isUseWater) {
          deviceOfModel.mesh.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.mesh.setEnabled(true)
        }
        // 2、改变接口数据

      },
      deep: true
    },
    useElectricInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getUseElectricInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["inside"][buildingIndex][floorIndex].find(device => device.name === deviceID)
        if (newObj.isUseElectric) {
          deviceOfModel.mesh.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.mesh.setEnabled(true)
        }
        // 2、改变接口数据

      },
      deep: true
    },
    coInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getCoInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["outside"].find(device => device.name === deviceID)
        if (newObj.isCo) {
          deviceOfModel.mesh.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.mesh.setEnabled(true)
        }
        // 2、改变接口数据

      },
      deep: true
    },
    waterInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getWaterInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["outside"].find(device => device.name === deviceID)
        if (newObj.isWater) {
          deviceOfModel.mesh.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.mesh.setEnabled(true)
        }
        // 2、改变接口数据

      },
      deep: true
    },
    fanInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getFanInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["inside"][buildingIndex][floorIndex].find(device => device.name === deviceID)
        if (newObj.isFan) {
          deviceOfModel.mesh.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.mesh.setEnabled(true)
        }
        // 2、改变接口数据

      },
      deep: true
    },
    liftInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getLiftInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["inside"][buildingIndex][floorIndex].find(device => device.name === deviceID)
        if (newObj.isLift) {
          deviceOfModel.mesh.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.mesh.setEnabled(true)
        }
        // 2、改变接口数据

      },
      deep: true
    },
    airInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getAirInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["inside"][buildingIndex][floorIndex].find(device => device.name === deviceID)
        if (newObj.isAir) {
          deviceOfModel.mesh.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.mesh.setEnabled(true)
        }
        // 2、改变接口数据

      },
      deep: true
    },
    dynamoInfo: {
      handler: function (newObj, oldObj) {
        this.$emit("getDynamoInfo", newObj)
        // 1、弹窗的开关与图标的实际状态对应
        let deviceOfModel = DeviceAPI.devicePos["inside"][buildingIndex][floorIndex].find(device => device.name === deviceID)
        if (newObj.isDynamo) {
          deviceOfModel.mesh.setEnabled(false)
        } else {
          this.restorePerspective()
          deviceOfModel.mesh.setEnabled(true)
        }
        // 2、改变接口数据

      },
      deep: true
    },
    chosePoint: {
      handler: function (newValue, oldValue) {
        if (this.isLight) {
          Message.setPopPosition(this.popArr.lightPop, this.chosePoint, this.isLight)
        } else if (this.isBroadcast) {
          Message.setPopPosition(this.popArr.broadcastPop, this.chosePoint, this.isBroadcast)
        } else if (this.isManage) {
          if (this.isRoomUse) {
            Message.setPopPosition(this.popArr.manageUsePop, this.chosePoint, this.isRoomUse)
          } else {
            Message.setPopPosition(this.popArr.manageFreePop, this.chosePoint, !this.isRoomUse)
          }
        } else if (this.isVideo) {
          Message.setPopPosition(this.popArr.monitorPop, this.chosePoint, this.isVideo)
        }
      }
    },
    isShellGone: {
      handler: function (newValue, oldValue) {
        scene.collisionsEnabled = !newValue;
        DeviceAPI.iconObserver._willBeUnregistered = !newValue
      }
    }
  },
  methods: {
    //  初始化操作
    initOperation() {
      // 建筑列表
      let buildings = [];
      for (let key in Global.schoolEquipment.inside) {
        let obj = {
          value: key,
          label: key
        };
        buildings.push(obj);
      }
      this.buildingArr = buildings;
      // 当前页面应存在哪类设备
      switch (this.routeName) {
        case "TeachingManagement":
          deviceType = 'manage'
          break
        case "Monitor":
          inRoomEquipment = hiScene.objects.filter(item => item.type === "gltf" && item.name.match("F-监控"))
          deviceType = 'monitor'
          break
        case "Broadcast":
          inRoomEquipment = hiScene.objects.filter(item => item.type === "gltf" && item.name.match("F-广播"))
          deviceType = 'broadcast'
          break
        case "Illumination":
          deviceType = 'light'
          break
        case "EnergyConsumption":
          deviceType = 'waterTank'
          break
        case "CO":
          deviceType = 'coMonitor'
          break
        case "Water":
          deviceType = 'waterMonitor'
          break
        case "ExhaustAir":
          deviceType = 'Fan'
          break
        case "Lift":
          deviceType = 'lift'
          break
        case "AirConditioning":
          deviceType = 'airConditioning'
          break
        case "Electricity":
          deviceType = 'dynamo'
          break
        default:
          break
      }
    },
    // 点击图标事件
    clickIcon() {
      // 移除存在的onPointerObservable
      if (DeviceAPI.iconObserver) {
        scene.onPointerObservable.remove(DeviceAPI.iconObserver)
      }
      DeviceAPI.iconObserver = scene.onPointerObservable.add((pointerInfo) => {
        let meshName = pointerInfo.pickInfo.pickedMesh.name
        let firstIndex = meshName.indexOf("-")
        deviceType = meshName.slice(0, firstIndex)
        deviceID = meshName.slice(firstIndex + 1, meshName.length)
        let deviceTypeArr = Object.keys(DeviceAPI.deviceInterfaceData)
        if (deviceTypeArr.includes(deviceType)) {
          let device = DeviceAPI.deviceInterfaceData[deviceType].find(item => item.DEVICE_ID === deviceID)
          // 1、相机移动
          let pickIcon = pointerInfo.pickInfo.pickedMesh
          let cameraPos = {
            target: {
              x: pickIcon.position._x,
              y: pickIcon.position._y,
              z: pickIcon.position._z,
            },
            position: {
              x: pickIcon.position._x - 5.0,
              y: pickIcon.position._y - 1.0,
              z: pickIcon.position._z,
            }
          }
          Global.flyTo(cameraPos)
          // 2、显示弹窗
          switch (deviceType) {
            case "manage":
              break
            case "monitor":
              this.monitorInfo.isMonitor = true
              this.monitorInfo.deviceName = device.DEVICE_NAME
              this.monitorInfo.chosePoint = pickIcon.position
              break
            case "broadcast":
              this.broadcastInfo.isBroadcast = true
              this.broadcastInfo.deviceName = device.DEVICE_NAME
              this.broadcastInfo.isBroadcastOn = device.DEVICE_STATUS
              this.broadcastInfo.sliderValue = device.DEVICE_VALUE
              this.broadcastInfo.chosePoint = pickIcon.position
              break
            case "light":
              break
            case "waterTank":
              break
            case "powerBox":
              break
            case "coMonitor":
              break
            case "waterMonitor":
              break
            case "Fan":
              break
            case "lift":
              break
            case "airConditioning":
              break
            case "dynamo":
              break
            default:
              break
          }
        }
      }, BABYLON.PointerEventTypes.POINTERUP);
      DeviceAPI.iconObserver._willBeUnregistered = true
    },
    // 恢复视角
    restorePerspective() {
      let point = null
      if (this.buildingName) {
        point = Global.buildingPos[this.buildingName]
      } else {
        point = Global.views[this.routeName]
      }
      Global.flyTo(point);
    },
    // 俯视视角
    overlooking() {
      let point = null
      if (this.buildingName) {
        point = Global.overlooking[this.buildingName];
      } else {
        point = Global.overlooking["全局"];
      }
      Global.flyTo(point);
    },
    // 关闭出现的弹窗
    closePop() {
      switch (this.routeName) {
        case "TeachingManagement":
          this.isManage = false
          break
        case "Monitor":
          this.isVideo = false
          break
        case "Broadcast":
          this.broadcastInfo.isBroadcast = false
          break
        case "Illumination":
          this.isLight = false
          break
        case "EnergyConsumption":
          this.useWaterInfo.isUseWater = false
          this.useElectricInfo.isUseElectric = false
          break
        case "ExhaustAir":
          this.coInfo.isCo = false
          break
        case "Lift":
          this.liftInfo.isLift = false
          break
        case "AirConditioning":
          this.airInfo.isAir = false
          break
        case "Electricity":
          this.dynamoInfo.isDynamo = false
          break
        default:
          break
      }
    },
    // 将相机视角从灯光图标处恢复到初始视角
    closeLight() {
      this.isLight = false
      if (this.classroomNumber === "室外") {
        let point = Global.views["Illumination"];
        Global.flyTo(point);
        let lightPlane = scene.getMeshByName("lightPlaneOn_" + this.classroomNumber + "_" + this.equipmentName);
        let lightPlaneOff = scene.getMeshByName("lightPlaneOff_" + this.classroomNumber + "_" + this.equipmentName);
        lightPlane.setEnabled(this.isLightOn);
        lightPlaneOff.setEnabled(!this.isLightOn);
      }
    },
    closeBroadcast() {
      this.isBroadcast = false
      let point = Global.views["Broadcast"];
      Global.flyTo(point);
      // Global.Func.cameraMoveTo(this.buildingName);

      camera.checkCollisions = true
      let broadcastPlaneOn = scene.getMeshByName("broadcastPlaneOn_" + this.classroomNumber + "_" + this.equipmentName);
      let broadcastPlaneOff = scene.getMeshByName("broadcastPlaneOff_" + this.classroomNumber + "_" + this.equipmentName);
      broadcastPlaneOn.setEnabled(this.isBroadcastOn)
      broadcastPlaneOff.setEnabled(!this.isBroadcastOn)
    },
    setIconPlane() {
      // 隐藏所有图标
      Global.insideIcon.forEach(mesh => mesh.setEnabled(false))
      Global.outsideIcon.forEach(mesh => mesh.setEnabled(false))
      // 显示当前页面的室外图标
      Global.schoolEquipment.outside.forEach(equipment => {
        if (this.routeName === "Monitor" && "mesh" in equipment && equipment.type === 'monitor') {
          equipment.mesh.setEnabled(true)
        } else if (this.routeName === "Broadcast" && "mesh" in equipment && equipment.type === 'broadcast') {
          equipment.mesh.on.setEnabled(equipment.state)
          equipment.mesh.off.setEnabled(!equipment.state)
        } else if (this.routeName === "Illumination" && "mesh" in equipment && equipment.type === 'light') {
          equipment.mesh.on.setEnabled(equipment.state)
          equipment.mesh.off.setEnabled(!equipment.state)
        } else if (this.routeName === "CO" && "mesh" in equipment && equipment.type === 'coMonitor') {
          equipment.mesh.setEnabled(true)
        } else if (this.routeName === "Water" && "mesh" in equipment && equipment.type === 'waterMonitor') {
          equipment.mesh.setEnabled(true)
        }

      })
      scene.onPointerObservable.remove(Global.observer)
      // 显示属于当前页面的设备模型
      if (this.routeName === "Monitor") {
        inRoomEquipment = hiScene.objects.filter(item => item.type === "gltf" && item.name.match("F-监控"))
        if (inRoomEquipment.length !== 0) {
          inRoomEquipment.forEach(model => {
            model.bNode.setEnabled(true);
          });
        }
      } else if (this.routeName === "Broadcast") {
        inRoomEquipment = hiScene.objects.filter(item => item.type === "gltf" && item.name.match("F-广播"))
        if (inRoomEquipment.length !== 0) {
          inRoomEquipment.forEach(model => {
            model.bNode.setEnabled(true);
          });
        }
      }
      // 绑定鼠标监听事件
      Global.observer = scene.onPointerObservable.add((pointerInfo) => {
        let eventType = pointerInfo.event.type;
        if (!pointerInfo.pickInfo.pickedMesh) {
          return;
        }
        let meshName = pointerInfo.pickInfo.pickedMesh.name
        // 判断点击的是否为图标
        let isIcon = false
        Global.planeNameArr.forEach(name => {
          if (meshName.match(name)) {
            isIcon = true
          }
        })
        if (!isIcon) {
          return;
        }

        let front = meshName.indexOf("_")
        let back = meshName.lastIndexOf("_")
        if (!back || !front) {
          return;
        }
        // 房间号
        let classroomNumber = meshName.slice(front + 1, back)
        // 设备名字
        let equipmentName = meshName.slice(back + 1, meshName.length)
        if (eventType === "pointermove") {

        }
        if (pointerInfo.event.button === 0 && eventType === "pointerdown") {
          // 选中监控图标
          if (meshName.match("monitorPlane")) {
            this.equipmentName = equipmentName
            this.classroomNumber = classroomNumber
            let mesh = scene.getMeshByName(meshName)
            // 相机移动
            let iconPosition = mesh.position
            this.chosePoint = mesh.position

            let cameraPos
            if (meshName.match("室外")) {
              cameraPos = {
                target: {
                  x: iconPosition._x,
                  y: iconPosition._y,
                  z: iconPosition._z,
                },
                position: {
                  x: iconPosition._x - 20.0,
                  y: iconPosition._y - 5.0,
                  z: iconPosition._z,
                }
              }
            } else {
              cameraPos = {
                target: {
                  x: iconPosition._x,
                  y: iconPosition._y,
                  z: iconPosition._z,
                },
                position: {
                  x: iconPosition._x - 5.0,
                  y: iconPosition._y - 2.0,
                  z: iconPosition._z,
                }
              }
            }

            Global.flyTo(cameraPos)
            let monitorPlane = scene.getMeshByName("monitorPlane_" + this.classroomNumber + "_" + this.equipmentName);
            monitorPlane.setEnabled(false)
            camera.checkCollisions = false

            // 弹窗显示
            let vector2 = Global.vector3Tovector2(iconPosition);
            this.isVideo = true;
            this.startVideo(vector2)
          }
          // 选中照明图标
          else if (meshName.match("lightPlane")) {
            this.equipmentName = equipmentName
            this.classroomNumber = classroomNumber
            let mesh = scene.getMeshByName(meshName)
            // 相机移动
            let iconPosition = mesh.position
            this.chosePoint = mesh.position
            let cameraPos = null
            if (meshName.match("室外")) {
              cameraPos = {
                target: {
                  x: iconPosition._x,
                  y: iconPosition._y,
                  z: iconPosition._z,
                },
                position: {
                  x: iconPosition._x - 19.0,
                  y: iconPosition._y - 5.0,
                  z: iconPosition._z,
                }
              }
            } else {
              cameraPos = {
                target: {
                  x: iconPosition._x,
                  y: iconPosition._y,
                  z: iconPosition._z,
                },
                position: {
                  x: iconPosition._x - 5.0,
                  y: iconPosition._y - 2.0,
                  z: iconPosition._z,
                }
              }
            }

            Global.flyTo(cameraPos)
            let lightPlane = scene.getMeshByName("lightPlaneOn_" + this.classroomNumber + "_" + this.equipmentName);
            let lightPlaneOff = scene.getMeshByName("lightPlaneOff_" + this.classroomNumber + "_" + this.equipmentName);
            lightPlane.setEnabled(false)
            lightPlaneOff.setEnabled(false)
            camera.checkCollisions = false

            // 弹窗显示
            this.isLight = true;
            let vector2 = Global.vector3Tovector2(iconPosition);
            new Global.drag("light_pop", vector2)
            this.isLightOn = !meshName.match("Off");
          }
          // 选中广播图标
          else if (meshName.match("broadcastPlane")) {
            this.equipmentName = equipmentName
            this.classroomNumber = classroomNumber
            let mesh = scene.getMeshByName(meshName)
            // 相机移动
            let iconPosition = mesh.position
            this.chosePoint = mesh.position
            let cameraPos = null
            if (meshName.match("室外")) {
              cameraPos = {
                target: {
                  x: iconPosition._x,
                  y: iconPosition._y,
                  z: iconPosition._z,
                },
                position: {
                  x: iconPosition._x - 20.0,
                  y: iconPosition._y - 5.0,
                  z: iconPosition._z,
                }
              }
            } else {
              cameraPos = {
                target: {
                  x: iconPosition._x,
                  y: iconPosition._y,
                  z: iconPosition._z,
                },
                position: {
                  x: iconPosition._x - 5.0,
                  y: iconPosition._y - 2.0,
                  z: iconPosition._z,
                }
              }
            }
            Global.flyTo(cameraPos)
            let broadcastPlaneOn = scene.getMeshByName("broadcastPlaneOn_" + this.classroomNumber + "_" + this.equipmentName);
            let broadcastPlaneOff = scene.getMeshByName("broadcastPlaneOff_" + this.classroomNumber + "_" + this.equipmentName);
            broadcastPlaneOn.setEnabled(false)
            broadcastPlaneOff.setEnabled(false)
            camera.checkCollisions = false

            // 弹窗显示
            this.isBroadcast = true;
            let vector2 = Global.vector3Tovector2(iconPosition);
            new Global.drag("broadcast_pop", vector2)
            this.isBroadcastOn = !meshName.match("Off");
          }
          // 选中教室图标
          else if (meshName.match("classroom")) {
            this.equipmentName = equipmentName
            this.classroomNumber = classroomNumber
            // 移到弹窗位置到模型处
            this.chosePoint = pointerInfo.pickInfo.pickedPoint
            let region = Global.schoolEquipment["inside"][this.buildingName][this.floorNum][classroomNumber]
            region.forEach(equipment => {
              if (equipment.name === equipmentName) {
                this.isRoomUse = !equipment.state
              }
            })
            this.isManage = true
          }
          // 选中用水量图标
          else if (meshName.match("useWaterPlane")) {
            this.useElectricInfo.isUseElectric = false

            this.useWaterInfo.classroomNumber = classroomNumber
            this.useWaterInfo.equipmentName = equipmentName
            this.useWaterInfo.isUseWater = true
            this.useWaterInfo.chosePoint = pointerInfo.pickInfo.pickedPoint
          }
          // 选中用电量图标
          else if (meshName.match("useElectricPlane")) {
            this.useWaterInfo.isUseWater = false

            this.useElectricInfo.classroomNumber = classroomNumber
            this.useElectricInfo.equipmentName = equipmentName
            this.useElectricInfo.isUseElectric = true
            this.useElectricInfo.chosePoint = pointerInfo.pickInfo.pickedPoint
          }
          // 选中co图标
          else if (meshName.match("coPlane")) {
            this.coInfo.classroomNumber = classroomNumber
            this.coInfo.equipmentName = equipmentName
            this.coInfo.isCo = true
            this.coInfo.chosePoint = pointerInfo.pickInfo.pickedPoint
            let region = Global.schoolEquipment["outside"]
            region.forEach(equipment => {
              if (equipment.name === equipmentName) {
                this.coInfo.location = equipment.location
              }
            })
          }
          // 选中集水坑图标
          else if (meshName.match("waterPlane")) {
            this.waterInfo.classroomNumber = classroomNumber
            this.waterInfo.equipmentName = equipmentName
            this.waterInfo.isWater = true
            this.waterInfo.chosePoint = pointerInfo.pickInfo.pickedPoint
            let region = Global.schoolEquipment["outside"]
            region.forEach(equipment => {
              if (equipment.name === equipmentName) {
                this.waterInfo.location = equipment.location
              }
            })
          }
          // 选中送排风图标
          else if (meshName.match("fanPlane")) {
            this.fanInfo.classroomNumber = classroomNumber
            this.fanInfo.equipmentName = equipmentName
            this.fanInfo.isFan = true
            this.fanInfo.chosePoint = pointerInfo.pickInfo.pickedPoint
            let region = Global.schoolEquipment["inside"][this.buildingName][this.floorNum][classroomNumber]
            region.forEach(equipment => {
              if (equipment.name === equipmentName) {
                this.fanInfo.location = equipment.location
              }
            })
          }
          // 选中电梯图标
          else if (meshName.match("liftPlane")) {
            this.liftInfo.classroomNumber = classroomNumber
            this.liftInfo.equipmentName = equipmentName
            this.liftInfo.isLift = true
            this.liftInfo.chosePoint = pointerInfo.pickInfo.pickedPoint
            let region = Global.schoolEquipment["inside"][this.buildingName][this.floorNum][classroomNumber]
            region.forEach(equipment => {
              if (equipment.name === equipmentName) {
                this.liftInfo.location = equipment.location
              }
            })
          }
          // 选中空调图标
          else if (meshName.match("airPlane")) {
            this.airInfo.classroomNumber = classroomNumber
            this.airInfo.equipmentName = equipmentName
            this.airInfo.isAir = true
            this.airInfo.chosePoint = pointerInfo.pickInfo.pickedPoint
            let region = Global.schoolEquipment["inside"][this.buildingName][this.floorNum][classroomNumber]
            region.forEach(equipment => {
              if (equipment.name === equipmentName) {
                this.airInfo.location = equipment.location
              }
            })
          }
          // 选中发电机图标
          else if (meshName.match("dynamoPlane")) {
            this.dynamoInfo.classroomNumber = classroomNumber
            this.dynamoInfo.equipmentName = equipmentName
            this.dynamoInfo.isDynamo = true
            this.dynamoInfo.chosePoint = pointerInfo.pickInfo.pickedPoint
            let region = Global.schoolEquipment["inside"][this.buildingName][this.floorNum][classroomNumber]
            region.forEach(equipment => {
              if (equipment.name === equipmentName) {
                this.dynamoInfo.location = equipment.location
              }
            })
          }
        }
      });
    },
    // 设置照明开关开启或关闭
    setLight() {
      let lightPlane = scene.getMeshByName("lightPlaneOn_" + this.classroomNumber + "_" + this.equipmentName);
      let lightPlaneOff = scene.getMeshByName("lightPlaneOff_" + this.classroomNumber + "_" + this.equipmentName);
      if (this.classroomNumber === "室外") {
        Global.schoolEquipment.outside.forEach(equipment => {
          if (equipment.name === this.equipmentName) {
            equipment.state = this.isLightOn
          }
        })
      } else {
        let region = Global.schoolEquipment["inside"][this.buildingName][this.floorNum][this.classroomNumber]
        region.forEach(equipment => {
          if (equipment.name === this.equipmentName) {
            equipment.state = this.isLightOn
          }
        })
      }
      lightPlane.setEnabled(this.isLightOn);
      lightPlaneOff.setEnabled(!this.isLightOn);
    },
    // 设置广播开关开启或关闭
    setBroadcast() {
      let broadcastPlane = scene.getMeshByName("broadcastPlaneOn_" + this.classroomNumber + "_" + this.equipmentName);
      let broadcastPlaneOff = scene.getMeshByName("broadcastPlaneOff_" + this.classroomNumber + "_" + this.equipmentName);
      if (this.classroomNumber === "室外") {
        Global.schoolEquipment.outside.forEach(equipment => {
          if (equipment.name === this.equipmentName) {
            equipment.state = this.isBroadcastOn
          }
        })
      } else {
        let region = Global.schoolEquipment["inside"][this.buildingName][this.floorNum][this.classroomNumber]
        region.forEach(equipment => {
          if (equipment.name === this.equipmentName) {
            equipment.state = this.isBroadcastOn
          }
        })
      }
      broadcastPlane.setEnabled(this.isBroadcastOn);
      broadcastPlaneOff.setEnabled(!this.isBroadcastOn);
    },
    // 创建图标
    createIcon(iconName, url) {
      let icon = BABYLON.MeshBuilder.CreatePlane(iconName, {height: 4, width: 4});
      let materialPlane = new BABYLON.StandardMaterial("Mat", scene);
      materialPlane.diffuseTexture = new BABYLON.Texture(url, scene);
      materialPlane.diffuseTexture.hasAlpha = true;
      materialPlane.diffuseTexture.isRGBD = true;
      materialPlane.backFaceCulling = true;
      materialPlane.ambientColor = new BABYLON.Color3(1, 1, 1);
      materialPlane.disableLighting = true;
      icon.material = materialPlane;
      icon.setEnabled(false);
      icon.billboardMode = 7;
      return icon;
    },
    // 选择建筑
    choseBuilding(buildingName) {
      // 数据重置
      this.recovery()

      this.buildingName = buildingName
      switch (buildingName) {
        case "中医":
          buildingName = "一期一号楼"
          buildingIndex = "buildingOne"
          break
        case "针灸推拿":
          buildingName = "一期二号楼"
          buildingIndex = "buildingTwo"
          break
        case "中西医结合":
          buildingName = "一期三号楼"
          buildingIndex = "buildingThree"
          break
        case "中药":
          buildingName = "一期四号楼"
          buildingIndex = "buildingFour"
          break
        case "图书馆、标本馆、综合教学楼":
          buildingName = "一期五号楼"
          buildingIndex = "buildingFive"
          break
        case "行政综合楼":
          buildingName = "一期六号楼"
          buildingIndex = "buildingSix"
          break
        case "学术会议中心":
          buildingName = "一期七号楼"
          buildingIndex = "buildingSeven"
          break
        case "体育馆":
          buildingName = "一期八号楼"
          buildingIndex = "buildingEight"
          break
        case "食堂":
          buildingName = "一期九号楼"
          buildingIndex = "buildingNine"
          break
        case "宿舍S1~4":
          buildingName = "一期十号楼"
          buildingIndex = "buildingTen"
          break
        case "宿舍S5~7":
          buildingName = "一期十一号楼"
          buildingIndex = "buildingEleven"
          break
        default:
          break
      }
      // 外壳和玻璃
      gltfArr = [];
      let gltf = hiScene.findObjectByName(buildingName);
      let gltfGlass = hiScene.findObjectByName(buildingName + "_" + "玻璃");
      if (!gltf || !gltfGlass) {
        return;
      }
      gltfArr.push(gltf, gltfGlass);
      // 1、相机移动
      Global.flyTo(Global.buildingPos[this.buildingName]);
      floorArr = hiScene.objects.filter(item => item.name.match(buildingName) && item.name.match("F"));
      floorArr.forEach(item => item.bNode.setEnabled(true));

      // 2、显示的楼层
      this.floorList = this.floorListDefault.slice(0, floorArr.length);
    },
    // 选择楼层
    choseFloor(e) {
      // 楼层切换关闭弹窗
      this.closePop()
      // 第一次点击楼层时，建筑外壳消失动画
      if (!this.isShellGone) {
        this.vanishingAnimation(gltfArr);
        this.isShellGone = true
      }

      // 楼层号
      let index = parseInt(e.target.innerHTML.slice(0, 1))
      this.floorChange = this.choseIndex !== index;
      // 楼层没有切换时
      if (!this.floorChange) {
        return;
      }
      this.choseIndex = index

      // 显示楼层
      if (index > displayFloor.length && displayFloor.length !== 0) {
        appearFloor = floorArr.slice(displayFloor.length, index);
        displayFloor = floorArr.slice(0, index);
        hiddenFloor = floorArr.slice(index, floorArr.length);
        this.appearAnimation(appearFloor);
      }
      // 隐藏楼层
      else {
        displayFloor = floorArr.slice(0, index);
        hiddenFloor = floorArr.slice(index, floorArr.length);
        this.vanishingAnimation(hiddenFloor);
      }


      this.changeFloorIcon(index)
    },
    // 切换楼层时选择显示哪一层的设备模型以及图标
    changeFloorIcon(index) {
      // 隐藏室内设备模型
      if (inRoomEquipment.length !== 0) {
        inRoomEquipment.forEach(model => {
          model.bNode.setEnabled(false);
        });
      }
      // 隐藏所有图标
      for (let floor in DeviceAPI.devicePos["inside"][buildingIndex]) {
        let deviceModelArr = DeviceAPI.devicePos["inside"][buildingIndex][floor].filter(device => device.type === deviceType)
        deviceModelArr.forEach(device => {
          if (device.type === "broadcast" || device.type === "light" || device.type === "manage") {
            device.meshOn.setEnabled(false)
            device.meshOff.setEnabled(false)
          } else {
            device.mesh.setEnabled(false)
          }
        })
      }

      // 显示当前楼层图标(新方法)
      switch (index) {
        case 1:
          floorIndex = "floorOne";
          break
        case 2:
          floorIndex = "floorTwo";
          break
        case 3:
          floorIndex = "floorThree";
          break
        case 4:
          floorIndex = "floorFour";
          break
        case 5:
          floorIndex = "floorFive";
          break
        case 6:
          floorIndex = "floorSix";
          break
        default:
          break
      }
      let deviceModelArr = DeviceAPI.devicePos["inside"][buildingIndex][floorIndex].filter(device => device.type === deviceType)
      let time = setTimeout(() => {
        deviceModelArr.forEach(device => {
          if (device.type === "broadcast") {
            device.meshOn.setEnabled(device.isOpen)
            device.meshOff.setEnabled(!device.isOpen)
          } else {
            device.mesh.setEnabled(true)
          }
        })
        // 显示当前楼层设备模型
        inRoomEquipment[index - 1].bNode.setEnabled(true)
        clearTimeout(time)
      }, 1000)
    },
    // 还原为原始状态
    recovery() {
      if (this.isShellGone) {
        // 1、恢复界面样式
        this.btnClick = true;
        this.buildingName = "选择教学楼";
        this.floorList = [];
        this.choseIndex = ''
        this.closePop()
        // 2、恢复消失的建筑外壳和楼层
        this.appearAnimation(gltfArr);
        gltfArr = [];
        this.isShellGone = false
        this.appearAnimation(hiddenFloor);
        displayFloor = [];
        appearFloor = [];
        hiddenFloor = [];
        // 3、隐藏室内楼层模型、设备模型、设备图标
        let time = setTimeout(() => {
          // 隐藏楼层
          floorArr.forEach(floor => floor.bNode.setEnabled(false));
          floorArr = [];
          // 隐藏室内设备模型
          if (inRoomEquipment.length !== 0) {
            inRoomEquipment.forEach(model => {
              model.bNode.setEnabled(false);
            });
          }
          // 隐藏所有图标
          for (let floor in DeviceAPI.devicePos["inside"][buildingIndex]) {
            let deviceArr = DeviceAPI.devicePos["inside"][buildingIndex][floor].filter(device => device.type === deviceType)
            deviceArr.forEach(device => {
              if (device.type === "broadcast") {
                device.meshOn.setEnabled(false)
                device.meshOff.setEnabled(false)
              } else {
                device.mesh.setEnabled(false)
              }
            })
          }
          this.btnClick = false;
          clearTimeout(time);
        }, 1000);
      }
    },
    // 模型消失动画
    vanishingAnimation(gltfArr) {
      // 建筑外壳和玻璃消失动画
      const frameRate = 10;
      const xSlide = new BABYLON.Animation("xSlide", "position.y", frameRate, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
      const keyFrames = [];

      keyFrames.push({
        frame: 0,
        value: 0
      });

      keyFrames.push({
        frame: frameRate,
        value: 200
      });

      xSlide.setKeys(keyFrames);

      gltfArr.forEach(gltf => {
        gltf.bNode.animations.push(xSlide);
        scene.beginAnimation(gltf.bNode, 0, frameRate, false);
      });
      let time = setTimeout(function () {
        gltfArr.forEach(gltf => gltf.bNode.setEnabled(false));
        clearTimeout(time);
      }, 500);

    },
    // 模型恢复动画
    appearAnimation(gltfArr) {
      gltfArr.forEach(gltf => {
        gltf.bNode.setEnabled(true);
      });
      // 建筑外壳和玻璃出现动画
      const frameRate = 10;
      const xSlide = new BABYLON.Animation("xSlide", "position.y", frameRate, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
      const keyFrames = [];

      keyFrames.push({
        frame: 0,
        value: 200
      });

      keyFrames.push({
        frame: frameRate,
        value: 0
      });

      xSlide.setKeys(keyFrames);

      gltfArr.forEach(gltf => {
        gltf.bNode.animations.push(xSlide);
        scene.beginAnimation(gltf.bNode, 0, frameRate, false);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.floor_list {
  position: fixed;
  width: 100px;
  //background-color: rgba(255, 255, 255, 0.2);
  bottom: 130px;
  left: 380px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  .floor1 {
    margin-top: 20px;
  }
}

.view_switching {
  position: fixed;
  width: 100px;
  //background-color: rgba(255, 255, 255, 0.2);
  top: 105px + 27px;
  right: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .view_btn {
    width: 55px;
    height: 55px;
    margin-bottom: 10px;
    cursor: pointer;

    .view_back {
      width: 50px;
      height: 50px;
      //margin-top: 2.5px;
      margin-left: 2.5px;
      background-image: url("../assets/image/icon/view_back.png");
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      float: left;
      animation: rotate 5s linear infinite;
    }

    .restore_perspective {
      width: 100%;
      height: 100%;
      background-image: url("../assets/image/icon/restore_perspective.png");
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }

    .overlooking {
      width: 100%;
      height: 100%;
      background-image: url("../assets/image/icon/overlooking.png");
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  //width: 100%;
  //height: 100%;
  background-color: rgba(255, 255, 255, 0.0);
  //z-index: 999;
}

.building_chose {
  width: 100%;
  height: 60px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.placeholderStyle {
  color: #E0E0E0;
}

.area {
  top: 105px;
}

.area_title {
  font-family: 'sthupo';
  color: #ffffff;
  font-size: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  margin-top: 27px;
  padding-bottom: 15px;
  position: relative;
}

.area_title_icon {
  width: 15px;
  height: 24px;
  background-image: url(../assets/image/icon/title_icon.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  margin-left: 10px;
}

.area_title_line {
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to right, rgba(245, 245, 245, 0.6) 0%, rgba(0, 0, 0, 0.4) 100%);
  width: calc(100% - 20px);
  height: 1px;
}

.area_title_line_ract {
  width: 3px;
  height: 5px;
  background-color: #d9d9d9;
  position: absolute;
  right: 0px;
  top: -1.5px;
}

.area_content_ {
  padding: 20px 10px 0;
}

.building_chose .el-select {
  // 选中时边框颜色
  ::v-deep .el-input__inner:focus {
    border-color: #30D6C2;
  }

  ::v-deep .el-popper.is-pure {
    background-color: rgba(0, 0, 0, 0);
    border-width: 0;
  }

  // option选项 上面的箭头
  ::v-deep .el-popper[data-popper-placement^=top] .el-popper__arrow::before {
    //border-bottom-color: rgba(43, 45, 55, 0.80);
    z-index: 9999;
    border: 2px solid #30D6C2;
    background-color: #30D6C2;
  }

  // option选项 下面的箭头
  ::v-deep .el-popper[data-popper-placement^=bottom] .el-popper__arrow::before {
    //border-bottom-color: rgba(43, 45, 55, 0.80);
    z-index: 9999;
    border: 2px solid #30D6C2;
    background-color: #30D6C2;
  }

  ::v-deep .popper__arrow {
    border: none;
  }

  // option选项 最外层
  ::v-deep .el-select-dropdown {
    //border: none !important;
    border: 2px solid #11EAD0;
    border-radius: 5px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.53) 0.21%, rgba(2, 68, 60, 0.9) 99.79%);
    z-index: 9999;
  }

  // option选项 文字样式
  ::v-deep .el-select-dropdown__item {
    color: rgba(242, 242, 242, 1.0) !important;
    z-index: 9999;
  }

  ::v-deep .el-select-dropdown__item.selected span {
    color: rgba(48, 214, 194, 1.0) !important;
    z-index: 9999;
  }

  // 移入option选项 样式调整
  ::v-deep .el-select-dropdown__item.hover {
    background-color: rgba(255, 255, 255, 0.06);
    color: rgba(48, 214, 194, 1.0) !important;
    z-index: 9999;
  }

  // 下拉框垂直滚动条宽度
  ::v-deep .el-scrollbar__bar.is-vertical {
    width: 10px;
    top: 2px;
  }

  // 下拉框最大高度
  //::v-deep .el-select-dropdown__wrap {
  //  max-height: 200px;
  //}
}

.recovery_btn {
  width: 70px;
  height: 56px;
  margin-left: 20px;
  cursor: pointer;
  border: 2px solid #30D6C2;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    font-family: 'normal';
    color: #30D6C2;
    font-size: 20px;
  }
}

.recovery_btn_1 {
  width: 70px;
  height: 56px;
  margin-left: 20px;
  cursor: pointer;
  border: 2px solid #F8D8B0;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    font-family: 'normal';
    color: #F8D8B0;
    font-size: 20px;
  }
}

.floor {
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.floors {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.floor_ {
  width: 71px;
  height: 31px;
  text-align: center;
  line-height: 31px;
  color: #FFF;
  background-image: url(../assets/image/bg/Group33.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  cursor: pointer;
}

.chose_floor {
  background-image: url(../assets/image/bg/Group42.png) !important;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.chose_building {
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-color: #30D6C2;
  /*color: #143F3A !important;*/
}
</style>
